import Popover from '@/components/ui/Popover/Popover';
import { useEffect, useState } from 'react';
import { HelpCircle, X, MessagesSquare } from 'lucide-react';
import clsx from 'clsx';
import Link from 'next/link';
import { FaWhatsapp } from "react-icons/fa";
import Text from '@/components/ui/Text';
import useMedia from '@/hooks/useMedia';
import { usePathname } from 'next/navigation';

type Props = {
  menuClose?: () => void;
}

const ChatWidget = ({menuClose} : Props) => {
  const [show, setShow] = useState(false);
  const isMobile = useMedia(992);
  const pathName = usePathname();
  const [isBasketPage, setIsBasketPage] = useState(false);
  const [isCheckoutPage, setIsCheckoutPage] = useState(false);
  const [isLanding,setIsLanding] = useState(false);

  useEffect(() => {

    if(pathName === "/") {
      setIsLanding(true);
    }

    else if(pathName === "/basket") {
      setIsBasketPage(true);
      setIsCheckoutPage(false);
    }

    else if(pathName === "/checkout") {
      setIsCheckoutPage(true);
      setIsBasketPage(false);
    }

    else {
      setIsCheckoutPage(false);
      setIsBasketPage(false);
    }

  }, [pathName])
  

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://webchat.monochat.ai/packs/js/sdk.js";
    script.defer = true;
    script.async = true;

    script.onload = () => {
      if (window?.chatwootSDK) {
        window.chatwootSDK.run({
          websiteToken: 'v1TnBRw91g3H5haWx3En1i2V',
          baseUrl: "https://webchat.monochat.ai/",
        });
        const bubbleElement = document.querySelector(".woot--bubble-holder");
        if (bubbleElement) {
          bubbleElement.remove();
        }
      } else {
        console.error('Chatwoot SDK yüklenemedi.');
      }
    };

    script.onerror = () => {
      console.error('Chatwoot SDK yüklenemedi.');
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const bubbleElement = document.querySelector(".woot--bubble-holder");
      if (bubbleElement) {
        bubbleElement.remove();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);


  const triggerChat = () => {
    if (window?.$chatwoot) {
      window.$chatwoot.toggle();
      if (isMobile) {
        setShow(false);
      }
    } else {
      console.error("Chat widget is not initialized.");
    }
  };

  const closeChatIfOpen = () => {
    if (window?.$chatwoot) {
      window.$chatwoot.toggle("close");
    }
    setShow(false);
  };

  if(!isLanding && isMobile) {
    return <div className="grid gap-2 grid-cols-2 w-full">
    <button
      onClick={() => {
        triggerChat();
        menuClose  && menuClose();
      }}
      className="flex justify-start items-center min-h-[35px] w-full px-2 py-2 gap-1 border border-extended_neutral-N100 rounded-component bg-extended_orange-B500 text-white"
    >
      <MessagesSquare  />
      <Text className="!text-buttonMd">Canlı Destek</Text>
    </button>
    <Link
    target="_blank"
      href="https://api.whatsapp.com/send?phone=902127060373&text="
      onClick={() => {
       menuClose  && menuClose();
      }}
      className="flex justify-start items-center min-h-[35px] w-full px-2 py-2 gap-1 border border-extended_neutral-N100 rounded-component bg-extended_green-G600 text-white"
    >
      <FaWhatsapp size={24} />
      <Text className="!text-buttonMd">WhatsApp Destek</Text>
    </Link>
  </div>
  }

  return (
    <div
    className={clsx('fixed bottom-5 right-3 z-[999]', {
      "bottom-[60px]": isMobile,
      "!bottom-[210px]": isMobile && isBasketPage,
      "!bottom-[240px]": isMobile && isCheckoutPage,
    })}
  >
    <div
      className={clsx('chatWidget', {
        "!-bottom-[100vh] !top-[unset] !opacity-0 !invisible -z-[1] translate-y-[40px]": !show,
        "!bottom-[140px]": isMobile && show,
        "!bottom-[280px]": isBasketPage && isMobile && show,
        "!bottom-[310px]": isCheckoutPage && isMobile && show,
      })}
    >
      <Link
        target="_blank"
        href="https://api.whatsapp.com/send?phone=902127060373&text="
        className="chatButton"
      >
        <FaWhatsapp size={24} />
        <Text className="!font-semibold !text-paragraphMd">WhatsApp Destek</Text>
      </Link>
      <button className="chatButton" onClick={triggerChat}>
        <MessagesSquare size={24} />
        <Text className="!font-semibold !text-paragraphMd">Canlı Destek</Text>
      </button>
    </div>
    <button
      onClick={() => (show ? closeChatIfOpen() : setShow(true))}
      className="w-16 h-16 rounded-full bg-extended_orange-B500 text-white flex justify-center items-center"
    >
      {show ? <X size={40} /> : <HelpCircle size={40} />}
    </button>
  </div>
  );
};

export default ChatWidget;
