import { motion } from "framer-motion";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useDialogStore } from "@/stores/Dialog/Dialog";
import { IDialogProps } from "@/types/Dialog/IDialog";
import Text from "@/components/ui/Text";
import Button from "@/components/ui/Buttons/Button";
import { XMarkNormal } from "@/components/icons";
import useIsFirstRender from "@/hooks/useIsFirstRender";
import useMedia from "@/hooks/useMedia";
import { BottomSheet } from "react-spring-bottom-sheet";

interface Props extends IDialogProps {
  dialogBgRef: React.RefObject<HTMLDivElement>;
}

const Dialog = (props: Props) => {
  const removeDialog = useDialogStore((x) => x.removeDialog);
  const dialogRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useIsFirstRender();
  const isTablet = useMedia(991);
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    if (!isFirstRender) {
      const clickHandle = (e: MouseEvent) => {
        if (
          dialogRef.current &&
          !dialogRef.current.contains(e.target as Node)
        ) {
          removeDialog(props.id?.toString() || "");
        }
      };
      document.addEventListener("click", clickHandle);
      return () => {
        document.removeEventListener("click", clickHandle);
      };
    }
  }, [removeDialog, props.id]);


  if(isTablet){
    return  <BottomSheet
    open={open}
    onDismiss={() => setOpen(false)}
    blocking
    tabIndex={1}
    header={
      <div className="pb-2 border-b border-extended_neutral-N100 flex justify-between items-center">
        <Text variant="h6" as="h6">
          {props?.title}
        </Text>
      
      </div>
    }
    footer={
      <div className="border-extended_neutral-N100">
          {props?.footer}
        </div>
    }
    /*snapPoints={({ maxHeight }) => [maxHeight]}*/
  >
   <div className="p-2">{props.content}</div>
  </BottomSheet>
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -100, x: "-50%" }}
      animate={{ opacity: 1, y: "-50%", x: "-50%" }}
      exit={{ opacity: 0, y: 50 }}
      className={clsx(
        "px-component-x py-component-y bg-extended_neutral-N0 rounded-page absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full mobile:w-fit laptop:max-w-[500px] pointer-events-auto cursor-pointer"
      )}
      ref={dialogRef}
    >
      <div className="pb-2 border-b border-extended_neutral-N100 flex justify-between items-center">
        <Text variant="h6" as="h6">
          {props?.title}
        </Text>
        <Button
          variant="icon"
          color="light"
          size="small"
          onClick={() => {
            removeDialog(props.id!);
          }}
        >
          <XMarkNormal />
        </Button>
      </div>
      <div className="py-2">{props.content}</div>
      {props?.footer && (
        <div className="pt-2 border-t border-extended_neutral-N100">
          {props?.footer}
        </div>
      )}
    </motion.div>
  );
};

export default Dialog;
