"use client";
import React from "react";
import clsx from "clsx";
import { Quicksand } from "next/font/google";
import { useRouter } from "next/navigation";
import Button from "@/components/ui/Buttons/Button";
import { ArrowLeft, ChevronLeft, Home } from "@/components/icons";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "@/components/landing/Footer/Footer";
import ToastContainer from "@/components/ui/Toast/ToastContainer";
import FreshChat from "../layouts/company/FreshChat";
import DialogContainer from "../ui/Dialog/DialogContainer";
import { useGetSiteSettingsService } from "@/services/Shareds/Settings/useGetSiteSettings";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import ChatWidget from "../layouts/company/ChatWidget";
const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { data, isLoading } = useGetLandingSiteSettingsService();
  const auth_banner = data?.responseData?.data?.find(
    (x) => x.description == "auth_banner"
  );
  return (
    <AnimatePresence>
      <motion.body
        initial={{ scale: 0.99, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        exit={{ scale: 0.99, opacity: 0 }}
        className={clsx("pt-3")}
      >
        <main className="w-full h-full flex justify-center items-center flex-1">
          <div className="relative bg-extended_neutral-N0 px-2 py-2 mobile:px-4 mobile:py-4 gap-4 rounded-lg w-full mobile:w-[540px] tablet:w-[700px] laptop:w-[800px] desktop:w-[1000px]  mobile:h-[705px] flex">
            <div
              className="flex-1  rounded-lg hidden tablet:flex flex-shrink-0 w-full"
              style={{
                background: `url(${auth_banner?.image_url || ""})`,
              }}
            ></div>
            <div className="flex-1 w-full flex flex-col justify-center items-center relative">
              <div className="absolute left-2 top-2">
                <Button
                  onClick={() => router.back()}
                  variant="icon"
                  size="small"
                  color="light"
                >
                  <ArrowLeft
                    className="!text-extended_neutral-N200"
                    size={20}
                  />
                </Button>
              </div>
              <div className="absolute right-2 top-2">
                <Button
                  onClick={() => router.push("/")}
                  variant="icon"
                  size="small"
                  color="light"
                >
                  <Home className="!text-extended_neutral-N200" size={20} />
                </Button>
              </div>
              {children}
            </div>
          </div>
        </main>
        <ToastContainer />
        <DialogContainer />
        <ChatWidget />
      </motion.body>
    </AnimatePresence>
  );
};

export default MainLayout;
