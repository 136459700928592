import React from "react";
import {
  AlarmBell,
  CheckmarkCircle,
  PriceTag,
  XMarkNormal,
} from "@/components/icons";
import clsx from "clsx";
import { ToastTypes } from "@/types/Toast/ToastTypes";
import { IToastProps } from "@/types/Toast/IToast";
import Text from "@/components/ui/Text";
import { motion } from "framer-motion";
import useToast from "@/hooks/useToast";
import { useToastStore } from "@/stores/Toast/Toaster";
const typeClassName = (type: ToastTypes) => {
  switch (type) {
    case "info":
      return "text-extended_neutral-N700";
    case "success":
      return "text-extended_green-G500";
    case "warning":
      return "text-extended_yellow-Y600";
    case "error":
      return " text-extended_red-R400";
    default:
      return "text-extended_neutral-N700";
  }
};

const typeIcon = (type: ToastTypes) => {
  switch (type) {
    case "info":
      return <PriceTag size={24} />;
    case "success":
      return <CheckmarkCircle size={24} />;
    case "warning":
      return <AlarmBell size={24} />;
    case "error":
      return <XMarkNormal size={24} />;
    default:
      return "";
  }
};
const typeIconClassName = (type: ToastTypes) => {
  switch (type) {
    case "info":
      return "bg-extended_neutral-N700";
    case "success":
      return "bg-extended_green-G500";
    case "warning":
      return "bg-extended_yellow-Y600";
    case "error":
      return "bg-extended_red-R400";
    default:
      return "";
  }
};

const Toast = (props: IToastProps) => {
  const { type = "info" } = props;
  const removeToast = useToastStore((x) => x.removeToast);
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      onClick={() => removeToast(props.id!)}
      className={clsx(
        "px-component-x py-component-y bg-extended_neutral-N0 rounded-page w-full laptop:max-w-[350px] pointer-events-auto cursor-pointer  shadow-effect",
        typeClassName(type)
      )}
    >
      <div className="flex items-center gap-component-y-lg">
        <div
          className={clsx(
            "flex p-3 items-center justify-center rounded-page text-extended_neutral-N0",
            typeIconClassName(type)
          )}
        >
          {typeIcon(type)}
        </div>
        <div className="h-full flex flex-col justify-between items-start gap-1">
          <Text as="span" variant="h6">
            {props.title}
          </Text>
          {props.hint && (
            <Text as="p" variant="small">
              {props.hint}
            </Text>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Toast;
