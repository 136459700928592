import React, { useMemo, useState } from "react";
import Toast from "@/components/ui/Toast/Toast";
import { useToastStore } from "@/stores/Toast/Toaster";
import { AnimatePresence } from "framer-motion";

const ToastContainer = () => {
  const toasts = useToastStore((toast) => toast.toasts);
  return (
    <div className="fixed inset-0 flex flex-col items-end p-4 z-[99999999] space-y-4 pointer-events-none">
      <AnimatePresence>
        {toasts.map((toast, index) => (
          <Toast key={toast?.id || index} {...toast} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastContainer;
