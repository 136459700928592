import React, { useRef } from "react";
import { useDialogStore } from "@/stores/Dialog/Dialog";
import { AnimatePresence, motion } from "framer-motion";
import Toast from "@/components/ui/Toast/Toast";
import Dialog from "@/components/ui/Dialog/Dialog";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { SheetContext } from "@/components/shareds/Sheet/Sheet";
import useMedia from "@/hooks/useMedia";

const DialogContainer = () => {
  const dialogs = useDialogStore((dialog) => dialog.dialogs);
  const dialogBgRef = useRef<HTMLDivElement>(null);
  const isTablet = useMedia(991);


  if (!dialogs.length) {
    return null;
  }

  
  if(isTablet){
    return dialogs.map((dialog, index) => (
      <Dialog
        dialogBgRef={dialogBgRef}
        key={dialog.id || index}
        {...dialog}
      />
    ))
  }

  return ReactDOM.createPortal(
    <div
      ref={dialogBgRef}
      className="fixed inset-0 flex flex-col justify-center items-center p-4 z-[999999999] w-full h-full bg-black/70 space-y-4"
    >
      <AnimatePresence>
        {dialogs.map((dialog, index) => (
          <Dialog
            dialogBgRef={dialogBgRef}
            key={dialog.id || index}
            {...dialog}
          />
        ))}
      </AnimatePresence>
    </div>,
    document.body
  );
};

export default DialogContainer;
